//  icons
import {
  FiYoutube,
  FiInstagram,
  FiGithub,
  FiDribbble,
  FiLayout,
  FiSettings,
  FiPenTool,
  FiTag,
  FiMail,
  FiMapPin,
  FiLinkedin
} from 'react-icons/fi';

// companies icons
import FreelancerBrandIcon from './assets/img/brands/freelancer.png';
import UpworkBrandIcon from './assets/img/brands/upwork.png';
import FiverBrandIcon from './assets/img/brands/fiverr.png';
import BehanceBrandIcon from './assets/img/brands/behance.png';
import DribbbleBrandIcon from './assets/img/brands/dribbble.png';

// projects images
import Project1 from './assets/img/projects/p1.webp';
import Project2 from './assets/img/projects/p2.webp';
import Project3 from './assets/img/projects/p3.webp';
import Project4 from './assets/img/projects/p4.webp';
import Project5 from './assets/img/projects/p5.webp';
import Project6 from './assets/img/projects/p6.webp';

// skills images
import SkillImg1 from './assets/img/skills/Dynamics.png';
import SkillImg2 from './assets/img/skills/Azure.png';
import SkillImg3 from './assets/img/skills/CSharp.png';
import SkillImg4 from './assets/img/skills/TypeScript.png';
import SkillImg5 from './assets/img/skills/nextjs.png';
import SkillImg6 from './assets/img/skills/reactjs.png';
import SkillImg7 from './assets/img/skills/Powershell.png';
import SkillImg8 from './assets/img/skills/git.png';

// testimonial images
import TestiImage1 from './assets/img/testimonials/testimonial-1.webp';
import TestiImage2 from './assets/img/testimonials/testimonial-2.webp';
import TestiImage3 from './assets/img/testimonials/testimonial-3.webp';

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'cv',
    href: 'cv',
  },
  {
    name: 'certificates',
    href: 'certificates',
  },
  {
    name: 'contact',
    href: 'contact',
  }
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/in/sziemann/',
  },
  {
    icon: <FiGithub />,
    href: 'https://github.com/sz0711',
  },
  {
    icon: <FiMail />,
    href: 'mailto:info@sziemann.de',
  },
];

// companies
export const brands = [
  {
    img: FreelancerBrandIcon,
    href: '',
  },
  {
    img: UpworkBrandIcon,
    href: '',
  },
  {
    img: FiverBrandIcon,
    href: '',
  },
  {
    img: BehanceBrandIcon,
    href: '',
  },
  {
    img: DribbbleBrandIcon,
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'project name 1',
    category: 'UI/UX design',
  },
  {
    id: '2',
    image: Project2,
    name: 'project name 2',
    category: 'web development',
  },
  {
    id: '3',
    image: Project3,
    name: 'project name 3',
    category: 'UI/UX design',
  },
  {
    id: '4',
    image: Project4,
    name: 'project name 4',
    category: 'branding',
  },
  {
    id: '5',
    image: Project5,
    name: 'project name 5',
    category: 'web development',
  },
  {
    id: '6',
    image: Project6,
    name: 'project name 6',
    category: 'web development',
  },
];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'UI/UX Design',
  },
  {
    name: 'web development',
  },
  {
    name: 'branding',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
    title: 'Dynamics 365',
  },
  {
    image: SkillImg2,
    title: 'Azure',
  },
  {
    image: SkillImg3,
    title: 'C#',
  },
  {
    image: SkillImg4,
    title: 'TypeScript',
  },
  {
    image: SkillImg5,
    title: 'Next.js',
  },
  {
    image: SkillImg6,
    title: 'React.js',
  },
  {
    image: SkillImg7,
    title: 'PowerShell',
  },
  {
    image: SkillImg8,
    title: 'Git',
  }
];

// services
export const services = [
  {
    icon: <FiLayout />,
    name: 'Web Design',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiSettings />,
    name: 'Web Development',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiPenTool />,
    name: 'Branding',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
  {
    icon: <FiTag />,
    name: 'SEO',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio perferendis volm quibusdam ullam qui dolore.',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage2,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
  {
    authorImg: TestiImage3,
    authorText:
      'Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.',
    authorName: 'Olivia Doe',
    authorPosition: 'Head of Design, Google',
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have a question?',
    subtitle: 'I am here to help you.',
    description: <a href="mailto:info@sziemann.de">Email me at info@sziemann.de</a>,
  },
  {
    icon: <FiMapPin />,
    title: 'Current Location',
    subtitle: 'Stuttgart',
    description: 'Germany',
  },
];

export const certificates = [
  {
    category: 'Azure',
    items: [
      'MICROSOFT AZ-104: AZURE ADMINISTRATOR ASSOCIATE',
      'MICROSOFT AZ-203: DEVELOPING SOLUTIONS FOR MICROSOFT AZURE',
      'MICROSOFT AZ-900: AZURE FUNDAMENTALS'
    ]
  },
  {
    category: 'Data analytics',
    items: [
      'MICROSOFT DP-600: FABRIC ANALYTICS ENGINEER ASSOCIATE'
    ]
  },
  {
    category: 'Dynamics 365',
    items: [
      'MICROSOFT MB-240: MICROSOFT DYNAMICS 365 FIELD SERVICE FUNCTIONAL CONSULTANT',
      'MICROSOFT MB-210: MICROSOFT DYNAMICS 365 SALES FUNCTIONAL CONSULTANT'
    ]
  },
  {
    category: 'Power Platform',
    items: [
      'MICROSOFT PL-600: POWER PLATFORM SOLUTION ARCHITECT',
      'MICROSOFT PL-400: MICROSOFT POWER PLATFORM DEVELOPER'
    ]
  },
  {
    category: 'Web Development',
    items: [
      'MICROSOFT 70-480: PROGRAMMING IN HTML5 WITH JAVASCRIPT AND CSS3'
    ]
  }
];

// CV positions
export const cvPositions = [
  {
    type: 'work',
    company: 'MODUS CONSULT Stuttgart',
    position: 'Technical Solution Architect',
    period: '02/2024-Present',
    responsibilities: [
      'Responsible for leading the design and execution of a large-scale Dynamics 365 CE and SAP integration project, leveraging Azure for efficient and secure system integration',
      'Implementation of Power Platform and Dynamics 365 CE customizations across Sales, Service, Field Service and Marketing in agile enterprise projects',
      'Designing and validation of Azure-based integration solutions, ensuring reliable data exchange and process automation',
      'Establishing and managing CI/CD pipelines using Azure DevOps, adhering to architectural guidelines and best practices',
      'Providing technical leadership and lifecycle support for enterprise-wide Dynamics 365 CE transformations'
    ],
  },
  {
    type: 'work',
    company: 'DIGITALL Böblingen',
    position: 'Principal Technical Consultant',
    period: '11/2023-01/2024',
    responsibilities: [
      'Responsible for the setup of a Dynamics 365 CE demo environment (Sales, Service & Customer Insights Journeys)',
      'Implementation of Dynamics 365 CE (Sales) customization in an agile enterprise project in the financial sector',
      'Validation and implementation of a backend interface between Dynamics 365 CE (Sales) and a document management system (DMS)',
      'PCF Development',
    ],
  },
  {
    type: 'work',
    company: 'KONICA MINOLTA BUSINESS SOLUTIONS DEUTSCHLAND Stuttgart',
    position: 'Senior Developer',
    period: '08/2021-10/2023',
    responsibilities: [
      'Comprehensive digitalization consulting with a focus on Microsoft Cloud technologies, particularly Microsoft Power Platform, Microsoft Teams, Dynamics 365 CE (Sales), SharePoint, and Azure',
      'Development of PoCs (proof of concept)',
      'Technological leadership for front- and backend development',
    ],
  },
  {
    type: 'work',
    company: 'KONICA MINOLTA BUSINESS SOLUTIONS DEUTSCHLAND Stuttgart',
    position: 'Consultant ECM/SharePoint',
    period: '07/2019-08/2021',
    responsibilities: [
      'Responsible for agile management, consulting and execution of national and international customer projects with a focus on Microsoft SharePoint (e.g., invoice processing on SharePoint)',
      'Conducting needs analyses and user requirement assessments',
      'Creating business concepts based on requirements workshops',
      'Supporting other project teams in all phases (planning, design, effort estimation, implementation and deployment) of project execution',
      'Training and support of internal teams and key users',
    ],
  },
  {
    type: 'work',
    company: 'KONICA MINOLTA BUSINESS SOLUTIONS DEUTSCHLAND Stuttgart',
    position: 'SharePoint Consultant/Developer',
    period: '12/2015-06/2019',
    responsibilities: [
      'Implementation of SharePoint solutions to meet business requirements',
      'Developing custom SharePoint web parts and workflows',
      'Managing SharePoint site collections',
      'Automating administrative tasks and deployment with SharePoint PowerShell scripts',
    ],
  },
  {
    type: 'work',
    company: 'JÖRG PAULE INFORMATIONSSYSTEME Fellbach',
    position: 'Developer',
    period: '09/2009-09/2014',
    responsibilities: [
      'Develop and maintain various C# applications to ensure high performance and responsiveness',
      'Collaborate with cross-functional teams to define, design and deliver new features',
      'Implementing backend services and APIs',
      'Performing code reviews',
      'Creating and maintaining technical documentation',
    ],
  },
  {
    type: 'education',
    company: 'MEDIA UNIVERSITY Stuttgart',
    position: 'Bachelor of Science in Business Informatics',
    period: '09/2011-11/2015',
    responsibilities: [
      'Focus: Process management and software development',
      'Thesis: “Distributed Systems and Services in the Enterprise-Content-Management Environment with .NET 4.5”',
    ],
  },
  {
    type: 'education',
    company: 'JÖRG PAULE INFORMATIONSSYSTEME',
    position: 'IT specialist – application development',
    period: '09/2006-09/2009',
  },
];
